.toast {
    width: 100%;
    padding: 16px;
    border-radius: 2px;
    
    min-height: 100px;
    transition: all 150ms ease-in-out 150ms;
    transform: translate(150%, 0);

    background-color: var(--light-background);
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border-left: 8px solid var(--primary);
}

.toast--show {
    transform: translate(0, 0);
    z-index: 5;
}

.toast__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* bottom-toast end */

.toast__text {
    margin-left: 16px;
}

.toast__header {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.toast__message {
    margin: 0;
    font-size: 18px;
}

.toast--error {
    border-color: var(--error-red);
}

.toast--success {
    border-color: var(--success-green);
}

.toast__spinner {
    width: 75px;
}

@media print {
    .toast {
        display: none;
    }
}