.finalise__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-input {
  display: none;
}

.file-input__label {
  display: flex;
  align-items: center;
  /* width: 80%; */
  min-width: 300px;
  border: 1px solid var(--border);
  border-radius: 4px;
  padding: 32px;
  margin-bottom: 16px;
  cursor: pointer;
  background-color: white;
  transition: background-color 200ms ease-in-out;
  font-weight: 500;
}

.file-input__label svg {
  width: 32px;
  margin-right: 16px;
}

.file-input__label:hover {
  background-color: white;
}

.chat-container {
  border: 1px solid var(--border);
  border-radius: 4px;
  padding: 16px;
  background-color: white;
  height: 55vh;
  overflow-y: scroll;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.new-chat-form {
  display: flex;
  align-items: center;
}

.new-chat-form button {
  margin-left: 16px;
}

.chat__message {
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
  width: max-content;
  max-width: 75%;
  color: white;
}

.chat__message--user {
  align-self: flex-end;
  background-color: #425698;
}

.chat__message--ai {
  background-color: #7b7b7b;
}