.list {
  list-style-type: none;
  padding: 0;
}

.list__item {
  margin-bottom: 16px;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--border);
  cursor: pointer;
  transition: color 200ms ease-in-out;
}

.list__item:hover {
  color: var(--primary);
}