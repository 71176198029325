.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__button {
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 16px;
}

.nav__button svg, .nav__button {
  width: 32px;
  height: 32px;
  transition: color 300ms ease-in-out;
}

.nav__button:hover svg {
  color: var(--primary);
}