:root {
  --primary: #3462F9;
  --secondary: #F9CB34;
  --border: rgb(182, 182, 182);
  --light-background: #F3F6FA;
  --dark-background: #eaeaea;
  --error-red: red;
  --success-green: #2f8755;
  --text: #000000;
  --light-grey-background: #E5E5E5;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2
}

p, span, textarea, input, h1, h2, h3, h4, li, label, small, a {
  color: var(--text);
  font-family: sans-serif;
}

code, button {
  font-family: sans-serif;
}
button {
  cursor: pointer;
  background: none;
  border: 1px solid var(--border);
  border-radius: 3px;
}

.content {
  width: 95vw;
  margin: auto;
  padding: 16px;
  max-width: 1000px;
}

.button {
  font-family: sans-serif;
  color: white;
  background-color: var(--primary);
  display: block;
  font-size: 18px;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;
  border: 4px solid var(--primary);
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
}

.button:hover, .button:focus {
  color: var(--primary);
  background-color: white;
}

.button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.button--small {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: var(--text);
  font-size: 14px;
  padding: 4px 16px;
}

.button--secondary {
  background-color: white;
  color: var(--primary)
}

.button--secondary:focus, .button--secondary:hover {
  color: white;
  background-color: var(--primary);
}

.button__group {
  display: flex;
  align-items: center;
}

.button__group button {
  margin-right: 16px;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  color: var(--text);
  transition: color 300ms ease-in-out;
  cursor: pointer;
}

.link-button:hover {
  color: var(--primary)
}

.page__inner {
  background-color: white;
  border-radius: 12px;
  min-height: 600px;
  padding: 16px;
}

.page__inner + .page__inner {
  margin-top: 64px;
}

.page__label {
  margin: 0;
}

.page__title {
  margin-top: 0;
}

.loading__full-page {
  max-width: 200px;
  margin: auto;
  display: block;
  margin-top: 15vh;
}
